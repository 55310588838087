import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layouts/mainPage"
import { NewHeader } from "../../components/Headers/NewHeader"
import { Banner } from "../../components/Banners/ElenaPodkaminskayaPink"
import { HowInstallmentWorks } from "../../components/HowInstallmentWorks"
import { FormPKW } from "../../components/FormPKW"
import { NewCalculator } from "../../components/NewCalculator/NewCalculator"
import { MoreBenefitsRefinancing } from "../../components/MoreBenefitsRefinancing"
import { Partners } from "../../components/PartnersNew"
import { Reviews } from "../../components/Reviews"
import { NewFooter } from "../../components/NewFooter"

import { getPageData } from "../../helpers/getPageData"

import { PageDataWithVariables } from "../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../components/FourAdvantagesPoints"
import { BottomCta, RefinRedesign } from "../../components/BottomCta"
import { SwitcherTextBlock } from "../../components/SwitcherTextBlock"
import { useHybridBanner } from "../../hooks/useHybridBanner"
import { getVariableFromQuery } from "../../helpers/getVariableFromQuery"
import { getSearchParam } from "../../helpers/getSearchParam"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const GIFT_TITLE = (
  <>
    Оформите карту «Халва»
    <br />
    и&nbsp;получите&nbsp;стикер&nbsp;PAY
    <br />
    <b>бесплатно</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageDataWithVariables>) {
  const { ligal, noIndex } = getPageData(data)
  const pskParam = getSearchParam("psk")

  const queryData = getVariableFromQuery(pskParam, data)

  const bannerSimpleTitle = `
 До&nbsp;36&nbsp;месяцев рассрочки
    `
  const bannerSimpleDescription = `
    проценты заплатит  <br />
    магазин
  `

  const banner = useHybridBanner(
    Banner as any,
    { title: bannerSimpleTitle, desc: bannerSimpleDescription, orderNum: "1" },
    { orderNum: "1" },
    { orderNum: "1", isDynamicText: true },
    data.allDynamicBanner
  )

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader redLogoWithSovcombank showHint={false} hasCTA countDownTime={countDownTime} />
      {banner}
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        withTitle
        indent
        variant="redisignBezProcentov"
        orderNum="2"
      />

      <FormPKW
        dataLayerName="shortPersonalForm"
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        backgroundColor="#EBECFF"
        orderNum="3"
        progressBar
        isGreenApprove
      />
      <HowInstallmentWorks
        variant="newYearMedia"
        headTitle="Как получить рассрочку до&nbsp;36&nbsp;месяцев"
        orderNum="4"
      />
      <NewCalculator
        titleLocationBottom
        subtext=""
        subtitle=""
        additionalEventInDataLayer
        orderNum="5"
      />
      <MoreBenefitsRefinancing additionalEventInDataLayer bottomBlock={false} orderNum="6" />
      <Partners orderNum="7" />
      <BottomCta
        variant="refinRedesign"
        title={GIFT_TITLE}
        BgImg={RefinRedesign}
        orderNum="8"
        StickerPay
      />
      <SwitcherTextBlock
        mode="bezProcentov24MesMedia"
        orderNum="9"
        seoBlockText=""
        indent
        queryData={queryData}
      />
      <Reviews additionalEventInDataLayer orderNum="10" />

      <NewFooter ligal={ligal} orderNum="11" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/24-mesyaca-bez-procentov/media/") {
        url
        notIndex
        ligal {
          text
        }
      }
      variables {
        items {
          name
          value
        }
      }
    }
  }
`
